import { currencyCode } from '@lib/utils'
import prop from '@simplisafe/ewok/ramda/prop'
import { handleFacebookEvent } from '@simplisafe/ss-ecomm-data/facebook'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import T from 'ramda/src/T'
import { useCallback } from 'react'
import { UAParser } from 'ua-parser-js'
import Cookies from 'universal-cookie'

import { visitorIdAtAt } from '../atat'
import { OptimizelyPurchaseEvent } from '../optimizely'

const cookies = new Cookies()
const userAgentParser = new UAParser()
const userAgentData = userAgentParser.getResult()

export type FacebookPageViewData = {
    readonly eventId: string
    readonly pageUrl: string
}

export type FacebookPurchaseEventData = {
    readonly email: string
    readonly eventId: string
    readonly orderId: string
    readonly value: number
    readonly contents: ReadonlyArray<OptimizelyPurchaseEvent>
}

export const useFacebookTrackProductPurchase = () =>
  useCallback((data: FacebookPurchaseEventData) => {
    const value = prop('value', data)
    const email = prop('email', data)
    const orderId = prop('orderId', data)
    const contents = data.contents
    const fbp = cookies.get<string>('_fbp')
    const eventId = prop('eventId', data)
    const externalId = visitorIdAtAt() || ''

    handleFacebookEvent({
      actionSource: 'website',
      customData: {
        contents: JSON.stringify(buildContents(contents)),
        currency: currencyCode,
        orderId: orderId,
        value: value
      },
      eventId: eventId,
      eventName: 'Purchase',
      eventTime: (Math.floor((Date.now()) / 1000)).toString(),
      testCode: process.env.FACEBOOK_CAPI_TEST_CODE,
      userData: {
        clientUserAgent: userAgentData.ua,
        em: email,
        externalId: externalId,
        fbp: fbp
      }
    })(() => logError(Error('Conversion API Purchase failure')))(() => T)
  }, [  ])

export const useFacebookTrackSiteVisits = () =>
  useCallback((data: FacebookPageViewData) => {
    const fbp = cookies.get<string>('_fbp')
    const externalId = visitorIdAtAt() || ''

    handleFacebookEvent({
      actionSource: 'website',
      eventId: prop('eventId', data),
      eventName: 'PageView',
      eventSourceUrl: prop('pageUrl', data),
      eventTime: JSON.stringify(Math.floor((Date.now()) / 1000)),
      testCode: process.env.FACEBOOK_CAPI_TEST_CODE,
      userData: {
        clientUserAgent: userAgentData.ua,
        externalId: externalId,
        fbp: fbp
      }
    })(() => logError(Error('Conversion API PageView failure')))(() => T)
  }, [  ])


export const buildContents = (contents: ReadonlyArray<OptimizelyPurchaseEvent>) => {
  return contents.map(product => {
    return {

      id: prop('sku', product),
      // eslint-disable-next-line camelcase -- This is how Facebook want the purchase event contents formatted
      item_price: prop('price', product),
      quantity: prop('qty', product)
    }
  })
}
