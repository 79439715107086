import { WindowLocation } from '@reach/router'
import { document } from 'browser-monads-ts'
import assoc from 'ramda/src/assoc'
import has from 'ramda/src/has'
import identity from 'ramda/src/identity'
import ifElse from 'ramda/src/ifElse'
import { useEffect, useRef } from 'react'

/**
 * See the suggestion from https://github.com/reach/router/issues/119#issuecomment-423338960
 * This is intended to be used at the page wrapper level, and uses the location object from
 * reach-router to get the referring url by keeping track of location keys and previous urls:
 * - On mount, use document.referrer
 * - For a new location key, store it along with its previous url
 * - For a location key we've seen before, return its associated referring url
 */
const useReferrerUrl = (location: WindowLocation) => {
  const { href, key } = location
  // key should never *actually* be undefined, but the type library thinks it can be, so fall back on href just in case
  const locationKey = key || href
  const previousLocation = useRef<string>(document.referrer)
  const referrerMap = useRef<Record<string, string>>({ [locationKey]: previousLocation.current })

  useEffect(() => {
    previousLocation.current = href
  }, [ href ])

  // eslint-disable-next-line ramda/if-else-simplification -- legacy code
  const updateReferrerMap = ifElse(
    has(locationKey),
    identity,
    assoc(locationKey, previousLocation.current)
  )
  referrerMap.current = updateReferrerMap(referrerMap.current)

  return referrerMap.current[locationKey]
}

export default useReferrerUrl
