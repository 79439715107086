// START: ECP-5128 - BMS A/B test
// TODO: this should be temporary and should be ripped out when the BMS A/B test is complete.

import { fetchAtAtVisitorId, visitorIdAtAt } from '@lib/tracking/src/atat'
import { userAttributes } from '@lib/tracking/src/optimizely'
import { createInstance } from '@optimizely/react-sdk'
import {
  globalHistory, Redirect, useLocation
} from '@reach/router'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { exists, window } from 'browser-monads-ts'
import cond from 'ramda/src/cond'
import F from 'ramda/src/F'
import T from 'ramda/src/T'
import React, {
  useEffect, useMemo, useState
} from 'react'
import Skeleton from 'react-loading-skeleton'
import Cookies from 'universal-cookie'

// As a postinstall hook, this file is copied from packages/site/optimizelyDatafile.json, and at build time,
// it's populated with real data from Optimizely.
// NOTE: if you see a TS error because this file doesn't exist locally, run `yarn copy:optimizelyDatafile`.
import datafile from './optimizelyDatafile.json'

export const COOKIE_OPTIMIZELY_EXCLUDED = 'optimizely_excluded'
export const BMS_EXPERIMENT_KEY = 'ECP_5128'
export const BMS_CONTROL_URL = '/build-my-system'
// TODO: when cleaning up the BMS test and retiring this url, we should probably also add an entry to redirects/.com.json
// and redirects/.co.uk.json to redirect /build-my-system2 back to /build-my-system
export const BMS_VARIATION_URL = '/build-my-system2'

const isBmsUrl = (pathname: string) => [ BMS_CONTROL_URL, BMS_VARIATION_URL ].includes(pathname)

// Activates the Optimizely experiment if on a BMS page and not excluded, and returns the
export const activateBmsRedirectExperiment = (pathname: string, userId: string) => {
  const cookies = new Cookies()
  const isExcluded = !!cookies.get<string>(COOKIE_OPTIMIZELY_EXCLUDED)

  const optimizelyInstance = createInstance({
    datafile,
    datafileOptions: { autoUpdate: false },
    // don't batch events
    eventBatchSize: 1,
    eventFlushInterval: 1
  })

  const shouldActivateTest = !isExcluded && isBmsUrl(pathname)

  const variationId = shouldActivateTest ? optimizelyInstance.activate(BMS_EXPERIMENT_KEY, userId, userAttributes()) : null

  const redirectUrl = cond<string | null>([
    [ () => pathname === BMS_CONTROL_URL && variationId === 'variation_1', () => BMS_VARIATION_URL ],
    [ () => pathname === BMS_VARIATION_URL && (variationId === 'control' || !variationId), () => BMS_CONTROL_URL ],
    [ T, () => null ]
  ])()

  return {
    redirectUrl,
    testActivated: shouldActivateTest
  }
}

const BmsLoadingSkeleton = ({ redirectUrl }: { readonly redirectUrl?: string | null}) => (
  <div className='prose md:prose-md lg:prose-lg p-4 md:p-8 lg:p-16' data-component="loading-skeleton">
    <div className='mb-4 md:mb-8'>
      <h1><Skeleton /></h1>
      <p><Skeleton count={3} /></p>
    </div>
    { Array.from(Array(4)).map((_, i) => (
      <div className='my-4 md:my-8' key={`bms-skeleton-${i}`}>
        <Skeleton count={4} />
      </div>
    )) }
    {/* reach-router's Redirect component claims it *should* work without the `noThrow` prop, but
      * it completely broke when I tried it that way, and the way that the redirect works under the
      * hood without the `noThrow` prop is to throw an error that must be re-thrown if caught, which
      * seems non-ideal for New Relic. -csims*/}
    {redirectUrl && <Redirect noThrow to={redirectUrl} />}
  </div>
)

// Called from gatsby-browser.ts to activate the experiment & perform the redirect as quickly as possible
// if an initial/full page happens on the BMS page.
// Redirect will only occur if a user has visited the site previously (i.e. atat token is present). Otherwise
// the client-side context will poll and wait for the cookie to be set (see useBmsTestRedirect below)
export const navigateForBmsTest = () => {
  const userId = visitorIdAtAt()
  const { redirectUrl } = userId ? activateBmsRedirectExperiment(window.location.pathname, userId) : { redirectUrl: null }
  redirectUrl && globalHistory.navigate(redirectUrl, { replace: true })
}

// A hook to activate the experiment on the BMS page, render a loading skeleton, and perform an in-app
// redirect if needed.
export const useBmsTestRedirect = () => {
  const { pathname } = useLocation()
  const isSSR = !exists(window)
  const isBms = isBmsUrl(pathname)
  const [ isLoading, setLoading ] = useState(true)
  const [ experimentLoaded, setExperimentLoaded ] = useState(false)
  const [ userId, setUserId ] = useState<string>()
  const { redirectUrl, testActivated } = useMemo(() => !isSSR && isBms && userId ? activateBmsRedirectExperiment(pathname, userId) : {
    redirectUrl: null,
    testActivated: false
  }, [ pathname, isSSR, isBms, userId ])

  // Ensures that we're not prematurely firing the Optimizely decision (similar to our wrap-with-context implementation)
  fetchAtAtVisitorId()
    .then(id => setUserId(id))
    .catch(logError)

  useEffect(() => {
    testActivated && setExperimentLoaded(true)
  }, [ testActivated ])

  // This timer is a dumb workaround to force the loading skeleton to render a little bit longer.
  // The timeout length can be tweaked, but without it, reach-router
  useEffect(() => {
    redirectUrl && !experimentLoaded && setLoading(true)

    const timer = setTimeout(() => {
      setLoading(false)
    }, 100)

    return () => clearTimeout(timer)
  }, [ pathname, redirectUrl, experimentLoaded ])

  const renderLoadingSkeleton = isBms && (isLoading || !!redirectUrl)

  return renderLoadingSkeleton ? <BmsLoadingSkeleton redirectUrl={redirectUrl} /> : null
}

// END: ECP-5128 - BMS A/B test
