/* eslint-disable simple-import-sort/imports -- see note below */
/**
 * IMPORTANT NOTE: fetchIntercept MUST be imported BEFORE fetchAtAtToken otherwise this breaks in weird ways
 * and causes us to stop sending the X-Vid-Token header.
 * Long-term, we should stop using fetch-intercept, and the headers required for SS API requests should just
 * be handled by the actual functions that make the requests (in ss-ecomm-data, etc).
 * - csims
 */
import fetchIntercept from 'fetch-intercept'
import { fetchAtAtToken } from '@lib/tracking/src/atat'
import { safeProp } from '@simplisafe/monda'

// @ts-expect-error TS(7006) FIXME: Parameter 'config' implicitly has an 'any' type.
const getRequestConfig = async (isSSApiRequest: boolean, config, isOverride: boolean) => {
  // eslint-disable-next-line functional/no-conditional-statement
  if (isSSApiRequest) {
    // Wait for the AT-AT cookie to be available before firing off any requests to the SS api
    const atatToken = await fetchAtAtToken()

    const modifiedHeaders = {
      ...safeProp('headers', config).getOrElse({}),
      'Accept-Language': process.env.LOCALE,
      'X-Vid-Token': atatToken
    }

    const baseConfig = {
      ...config || {},
      headers: modifiedHeaders
    }

    return isOverride
      ? baseConfig
      : {
        ...baseConfig,
        credentials: 'include'
      }
  }

  return config
}

const knownApiUrlOverrides = [
  process.env.PRICES_API_OVERRIDE_URL,
  process.env.PROMOTIONS_API_OVERRIDE_URL
].filter(x => x)

/**
 * Intercepts all fetch requests.
 *
 * Targets requests to internal SimpliSafe APIs and modifies the headers.
 */
const interceptFetchHeaders = () => fetchIntercept.register({
  request: async (url, config) => {
    const isLocalSSApiRequest = knownApiUrlOverrides.some(x => x && url.includes(x))
    const isSSApiRequest = url.includes(process.env.BASE_SS_API_URL || '') || isLocalSSApiRequest
    const requestConfig = await getRequestConfig(isSSApiRequest, config, isLocalSSApiRequest)

    return [ url, requestConfig ]
  },
})

export default interceptFetchHeaders
