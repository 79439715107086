module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-ts-config-virtual-bad71fbb8d/0/cache/gatsby-plugin-ts-config-npm-1.1.6-9d3cfe2992-402754b66b.zip/node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"./config"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-1fef9dbd1d/0/cache/gatsby-plugin-google-tagmanager-npm-2.11.0-126efa848c-6c5e8dbc63.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"cookieFlags":"SameSite=Strict","dataLayerName":"dataLayer","id":"GTM-M9GZT6X","includeInDevelopment":true,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-use-query-params-virtual-51f46044e7/0/cache/gatsby-plugin-use-query-params-npm-1.0.1-878a7051ae-62027d9099.zip/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-df0082959d/0/cache/gatsby-plugin-styled-components-npm-5.20.0-0e03dd455d-2388f15ab5.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-76aee803cc/0/cache/gatsby-plugin-manifest-npm-2.12.1-284866089d-d9c0dc517a.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fff","display":"minimal-ui","icon":"src/assets/images/simplisafe-icon.png","name":"SimpliSafe Home Security Systems","short_name":"SimpliSafe","start_url":"/","theme_color":"#0f2544","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9e41f4724aa14673dfd1dc686bf1253a"},
    }]
