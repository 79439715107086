const deployEnvs = [ 'development', 'qa', 'stg', 'prd' ] as const
type DeployEnv = typeof deployEnvs[number]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateDeployEnv = (deployEnv: any): DeployEnv => {
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- legacy code
  return deployEnvs.includes(deployEnv) ? deployEnv : 'qa'
}

// this is set as a variable outside the getter function so it's available at runtime
const DEPLOY_ENV: DeployEnv = validateDeployEnv(process.env.DEPLOY_ENV)

export const getDeployEnv = (): DeployEnv => DEPLOY_ENV
