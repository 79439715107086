import { safeProp } from '@lib/utils'
import { VariationFragment } from '@lib/utils/src/variations/types'
import { option as O } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'

/**
 * Gets variations from a fragment and parses each variation using a schema.
 *
 * @package internal use only
 */
function getFragmentVariations<T extends VariationFragment<U>, U, V>(fragment: T | undefined, parser: (u: U) => V): readonly V[] {
  const emptyArray: readonly V[] = []
  return pipe(O.fromNullable(fragment),
    O.chain(safeProp('variations')),
    O.chain(safeProp('variations')),
    O.map(arr => arr.map(parser)),
    O.getOrElse(() => emptyArray)
  )
}

export default getFragmentVariations
