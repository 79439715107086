/* eslint-disable simple-import-sort/imports -- see note below */

import '@simplisafe/ss-react-components/styles.css'
import '../src/styles/global.css'
import 'react-loading-skeleton/dist/skeleton.css'

/**
 * IMPORTANT NOTE: interceptFetchHeaders MUST be imported first, otherwise fetch-intercept
 * can break in unexpected ways and cause us to stop sending the X-Vid-Token header to the backend.
 * Long-term, we should stop using fetch-intercept, and the headers required for SS API requests
 * should just be handled by the actual functions that make the requests (in ss-ecomm-data, etc).
 * - csims
 */
import interceptFetchHeaders from './intercept-fetch-headers'
import { navigateForBmsTest } from './activateBmsRedirectExperiment'
import wrapWithContext from './wrap-with-context'
import wrapWithProvider from './wrap-with-provider'

export const wrapRootElement = wrapWithProvider
export const wrapPageElement = wrapWithContext

// Registers fetch intercept.
// The return value can be used to deregister like `unregisterFetchHeaders()`.
export const unregisterFetchHeaders = interceptFetchHeaders()

// Called when the Gatsby browser runtime first starts: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
export const onClientEntry = navigateForBmsTest
