import { handleAtAtTrackingEvent } from '@lib/tracking/src/atat'
import { getUserId, getVisitorId } from '@lib/tracking/src/cookies'
import type { TrackingData } from '@simplisafe/ecomm-ts-types'

import { handleGTMTrackingEvent } from './gtm'

// Handler for tracking calls for the current event and tracking data
const handleTrackingEvent = (data: TrackingData) => {

  // Augment data from the event with values that can potentially change after page load
  const augmentedData: TrackingData = {
    ...data,
    userId: getUserId(),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    visitorId: getVisitorId()
  }

  handleGTMTrackingEvent(augmentedData)
  handleAtAtTrackingEvent(augmentedData)
}

export default handleTrackingEvent
