/* eslint-disable camelcase */
import { fetchAtAtVisitorId } from '@lib/tracking/src/atat'
import { updateFirstSeenUrl, userAttributes } from '@lib/tracking/src/optimizely'
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'
import { UserInfo } from '@optimizely/react-sdk/dist/utils'
import { ImmutablePackages } from '@simplisafe/ss-ecomm-data/packages'
import { ImmutableProducts } from '@simplisafe/ss-ecomm-data/products'
import { EvergreenPromotion } from '@simplisafe/ss-ecomm-data/promotions/lib'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { exists, window } from 'browser-monads-ts'
import { get } from 'local-storage'
import { Maybe } from 'monet'
import React, {
  FC, ReactElement, useRef,
  useState
} from 'react'

import InjectContext from '../src/components/InitializeStore/InjectContext'
import InjectPageTracking from '../src/tracking/InjectPageTracking'
import { useBmsTestRedirect } from './activateBmsRedirectExperiment'

export type PageContext = {
  readonly products: ImmutableProducts
  readonly packages: ImmutablePackages
  readonly evergreenPromotion: Maybe<EvergreenPromotion>
  readonly datafile: Record<string, string>
}

type WrapWithContextProps = {
  readonly element: ReactElement
  readonly props: {
    readonly pageContext: PageContext
  }
}

const WrapWithContext: FC<WrapWithContextProps> = ({ element, props }: WrapWithContextProps) => {
  const {
    // eslint-disable-next-line react/prop-types
    products, packages, datafile, evergreenPromotion
  // eslint-disable-next-line react/prop-types
  } = props.pageContext
  const isSSR = !exists(window)
  // https://github.com/optimizely/react-sdk/blob/a67ad8b0f4d588fda71f04e31adf3b12ed3f3549/src/utils.tsx#L22
  const [ userId, setUserId ] = useState<string | null>(null)

  // https://github.com/optimizely/react-sdk#load-the-datafile-synchronously
  // "When initializing with both the SDK key and datafile, the SDK will use the given datafile to start,
  // then download the latest version of the datafile in the background."
  // Wrapped in a useRef so we don't re-initialize an Optimizely client on every page transition.
  const optimizely = useRef(createInstance({
    datafile,
    eventBatchSize: 10,
    eventFlushInterval: 1000,
    sdkKey: isSSR ? undefined : process.env.OPTIMIZELY_FS_SDK_KEY
  }))

  /** Allows a user to bucket themselves into an Optimizely group for feature testing. */
  const feature_flag = get('feature_flag')

  fetchAtAtVisitorId()
    .then(id => {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      setUserId(id)
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      updateFirstSeenUrl(id)
      return id
    })
    .catch(logError)

  /**
   * OptimizelyProvider technically accepts a UserInfo object or a Promise<UserInfo>, but a Promise only works properly
   * in the constructor and doesn't get updated properly in componentDidUpdate. This is a dumb workaround where if we
   * don't have a userId set (i.e. we're still waiting for the AT-AT cookie to be set), we pass a pending Promise to
   * OptimizelyProvider to prevent it from being "ready", and then we pass a regular UserInfo object once the AT-AT
   * cookie has been set.
   */
  const optimizelyUser = !userId ? new Promise<UserInfo>(() => null) : {
    attributes: {
      ...userAttributes(),
      feature_flag
    },
    id: userId
  }

  // START: ECP-5128 - BMS A/B test
  // If we're on a BMS url and need to redirect to another BMS page for the A/B test, this returns a Redirect component with a loading skeleton.
  // Otherwise it returns null.
  const bmsTestRedirect = useBmsTestRedirect()
  // END: ECP-5128 - BMS A/B test

  return bmsTestRedirect || (
    <OptimizelyProvider
      optimizely={optimizely.current}
      user={ isSSR ? undefined : optimizelyUser }>
      <InjectPageTracking>
        <InjectContext
          evergreenPromotion={evergreenPromotion}
          packages={packages}
          products={products}>
          {element}
        </InjectContext>
      </InjectPageTracking>
    </OptimizelyProvider>
  )
}

// Wrap the wrapper in another wrapper to allow the use of React hooks
// https://github.com/gatsbyjs/gatsby/issues/22833#issuecomment-609370401
const Wrapper: FC<WrapWithContextProps> = ({ element, props }: WrapWithContextProps) => {
  return <WrapWithContext element={element} props={props} />
}

export default Wrapper
