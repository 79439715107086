import { z } from 'zod'

/**
 * Meta details contained in a variation container.
 */
export const metaSchema = z.object({
  internal: z.object({ content: z.string().default('') }).default({})
    .default({})
}).default({})

/**
 * Creates a schema with variation details from a base schema.
 */
export const variationSchema = <T extends z.Schema>(baseSchema: T) => z.object({
  variations: z.object({
    experimentKey: z.string().default(''),
    meta: metaSchema,
    variations: z.array(baseSchema).default([])
  }).default({})
})

/**
 * Adds variation details to a schema so you don't lose variation details if you need them.
 * Useful when you need to add a child schema to a page template.
 */
export const addVariationSchema = <T extends z.Schema>(baseSchema: T) => {
  return z.intersection(baseSchema, variationSchema(baseSchema)).default({})
}
