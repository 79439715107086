import { visitorIdAtAt } from '@lib/tracking/src/atat'
import { useFacebookTrackSiteVisits } from '@lib/tracking/src/facebook'
import { useOptimizelyActivateExperiment, useOptimizelyTrackPageVisit } from '@lib/tracking/src/optimizely'
import { getDeployEnv } from '@lib/utils/src/deployEnv'
import { useLocation } from '@reach/router'
import { TrackingData } from '@simplisafe/ecomm-ts-types'
import { safeProp } from '@simplisafe/monda'
import { selectLocale } from '@simplisafe/ss-ecomm-data/redux/select'
import { brazeTrackPageVisit, initBraze } from '@simplisafe/ss-ecomm-data/tracking/braze'
import React, {
  FC,
  ReactElement,
  useEffect,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { PageTitleContext } from './pageTitleContext'
import useReferrerUrl from './useReferrerUrl'

type InjectPageTrackingProps = {
  readonly children: ReactElement
}

const InjectPageTracking: FC<InjectPageTrackingProps> = ({ children }: InjectPageTrackingProps) =>  {
  const location = useLocation()
  const {
    key: locationKey,
    pathname,
    search
  } = location
  const referrer = useReferrerUrl(location)
  const visitorToken = visitorIdAtAt() || ''
  const facebookEventId = visitorToken + Date.now()
  const [ pageTitle, setPageTitle ] = useState<string>()
  const [ isPageTitleSet, setIsPageTitleSet ] = useState(false)
  const [ isPageLoadTracked, setPageLoadTracked ] = useState(false)
  const { Track, trackEvent } = useTracking<TrackingData>({
    environment: getDeployEnv(),
    facebookEventId: facebookEventId,
    pagePath: pathname,
    pageTitle,
    queryString: search,
    referrer: referrer
  })
  const locationPath = safeProp('pathname', location).orUndefined()
  const hostName = safeProp('hostname', location).getOrElse('')
  const siteLocale = useSelector(selectLocale)

  const optimizelyTrackPageVisit = useOptimizelyTrackPageVisit()
  const optimizelyActivateExperiment = useOptimizelyActivateExperiment()

  const facebookTrackSiteVisits = useFacebookTrackSiteVisits()

  /* --- react-tracking page load tracking --- */
  // Initialize Braze Web SDK
  useEffect(() => {
    initBraze()
  }, [])

  /* --- react-tracking page load tracking --- */
  useEffect(() => {
    // This might be overkill, and not sure if/when this would happen, but this but makes sure that
    // we only track a page load once, and not if the page title changes multiple times within one page load
    setPageLoadTracked(false)
    setIsPageTitleSet(false)
  }, [ locationKey ])

  useEffect(() => {
    // Tracks a page load event if pageTitle and location have changed
    !isPageLoadTracked && isPageTitleSet && (trackEvent({
      event: 'pageLoad',
      facebookEventId: facebookEventId
    }), setPageLoadTracked(true))
  }, [ isPageLoadTracked, isPageTitleSet, trackEvent ])

  const handleSetPageTitle = (pageTitle: string) => {
    setPageTitle(pageTitle)
    setIsPageTitleSet(true)
  }

  /* --- optimizely and braze page view tracking --- */

  useEffect(() => {
    optimizelyTrackPageVisit({ pageUrl: locationPath })
    brazeTrackPageVisit()
  // eslint-disable-next-line react-hooks/exhaustive-deps -- only track this once per page load
  }, [ locationPath ])

  useEffect(() => {
    facebookTrackSiteVisits({
      eventId: facebookEventId,
      pageUrl: location.origin + locationPath
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps -- only track this once per page load
  }, [ location ])

  const activateGenericUSExperiment: boolean = (siteLocale === 'en-US' && !hostName.includes('us.prd.'))
  useEffect(() => {
    activateGenericUSExperiment && optimizelyActivateExperiment({ experimentId: 'test_for_affirm_events' })
  }, [ activateGenericUSExperiment, optimizelyActivateExperiment ])

  return (
    <Track>
      <PageTitleContext.Provider value={handleSetPageTitle}>
        {children}
      </PageTitleContext.Provider>
    </Track>
  )
}

export default InjectPageTracking
