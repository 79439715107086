import { either as E } from 'fp-ts'
import _ from 'lodash/fp'

function isSyntaxError<T>(err: SyntaxError | T): err is SyntaxError {
  return _.has('stack', err)
}

/**
 * Parses an object like string into a JS object.
 *
 * @example
 *
 * stringToObject('{ "foo": "bar" }') // => right { foo: "bar" }
 * stringToObject('foo') // => left SyntaxError
 */
function stringToObject<T>(str: string): E.Either<SyntaxError, Record<string, T>>{
  return E.tryCatch<Error, Record<string, T>>(() => JSON.parse(str),
    e => isSyntaxError(e) ? e : SyntaxError(`${e}`))
}

export default stringToObject
